(function($R)
{

    $R.add('plugin', 'chart_links', {
        modals: {
            'chart_links': '<div class="modal-section" id="redactor-modal-charts"></div>',
        },

        init: function(app)
        {
            this.app = app;
            this.opts = app.opts;
            // define toolbar service
            this.toolbar = app.toolbar;
            this.insertion = app.insertion;
            this.chosen_chart;
            this.chartLinks;
        },

        // messages
        onmodal: {
            chart_links: {
                opened: function($modal, $form)
                {
                    //$form.getField('text').focus();
                },
                insert: function($modal, $form)
                {
                    this._insert(chosen_chart);
                },
                closed: function($modal, $form) {
                   //
                }
            }
        },

        start: function() {
            // set up the button
            var buttonData = {
                title: 'Add Chart',
                api: 'plugin.chart_links.open'
            };
            var $button = this.toolbar.addButton('chart_links', buttonData);

            if (!this.chartLinks) {
                return;
            }

            this.modal.addCallback('chart_link', $.proxy(this.chart_links.load, this));
        },

        get_charts: function() {
            var url = (this.opts.chartLinks) ? this.opts.chartLinks : this.opts.chart_links;
            var which_charts = $("#chart_list_options option:selected").val();
            var $select = $('#redactor-chart-links');
            $select.html('<option value="" id="loading_charts">Loading...</option>');

            $.getJSON(url, { 'which_charts' : which_charts }, $.proxy(function(data) {
                //console.log(data);
                $.each(data, $.proxy(function(k, v) {
                    $chart_data = v;

                    $.each($chart_data, $.proxy(function(key, val) {
                        if(key == 'id') { $id = val; }
                        if(key == 'title') { $title = val; }
                        if(key == 'date') { $date = val; }
                    }, this));

                    $select.append( $("<option>").val($id).html($date + " : " + $title) );

                }, this));

                $select.on('change', function() {
                    chosen_chart = $('#redactor-chart-links option:selected').val();
                });

                $('#loading_charts').html("Please select chart...");
                $('#redactor-chart-links').select2();


            }, this));
        },

        open: function() {
            var options = {
                title: "Add Chart",
                width: '600px',
                name: 'chart_links',
                handle: 'insert',
                commands: {
                    insert: { title: 'Add Chart' },
                    cancel: { title: 'Cancel' }
                }
            };

            this.app.api('module.modal.build', options);

            var $options_section = $('<section style="margin-bottom:1em;" />');
            var $options = $('<select id="chart_list_options" />');
            $options.append('<option value="1">Load all charts</option><option value="2">Load all charts excluding Nowcast</option><option value="3">Load only Nowcast</option>');
            $options_section.append($options);

            var $section = $('<section />');
            var $select = $('<select id="redactor-chart-links" />');
            $select.append('<option value="" id="loading_charts">Loading...</option>');
            $section.append($select);

            $('#redactor-modal-charts').prepend($section);
            $('#redactor-modal-charts').prepend($options_section);

            var that = this;
            $('#chart_list_options').change( function() {
                that.get_charts();
            });

            this.get_charts();

        },

        // private
        _insert: function(chart_id)
        {
            var that = this;

            $.get("/publishing/table_data/" + chart_id, function(data) {
              that.app.api('module.modal.close');
              that.insertion.insertHtml(data);
            });
        }

    });
})(Redactor);
